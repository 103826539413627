<template>
  <div class="Notice_wrap">
    <div class="Notice_left">动态交易信息</div>

    <div class="Notice_right">
      <div class="Notice_right_info_wrap">
        <div
          class="Notice_right_info_item"
          v-for="(item, index) in NoticeData"
          :key="item.name"
        >
          {{ item.name
          }}<span
            :style="
              index == 0
                ? 'color:#FF9700'
                : index == 1
                ? 'color:#18B8F7'
                : index == 2
                ? 'color:#F53758'
                : 'color:#05BFAA'
            "
            >{{ item.behavior }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
export default {
  setup() {
    const NoticeData = [
      {
        name: "阿里云XXXXXXX项目",
        behavior: "发布了招标公告",
      },
      {
        name: "阿里云XXXXXXX项目",
        behavior: "发布了招标公告",
      },
      {
        name: "阿里云XXXXXXX项目",
        behavior: "发布了招标公告",
      },
      {
        name: "阿里云XXXXXXX项目",
        behavior: "发布了招标公告",
      },
    ];
    const data = reactive({
      NoticeData,
    });

    return {
      ...data,
    };
  },
};
</script>

<style scoped>
@import url("./Notice.css");
</style>

