<template>
  <BasePage :hideLeft="true" :hideBanner="true">
    <!-- <Notice /> -->
    <!-- <Content /> -->
    <ContentView :haveFile="false" :haveTurner="false" />
  </BasePage>
</template>

<script>
import { reactive, computed } from "vue";
import BasePage from "@/components/BasePage/BasePage.vue";
import Notice from "./components/Notice/Notice";
import ContentView from "./components/ContentView/ContentView.vue";
import { useRouter, useRoute } from "vue-router";
import { mainStore, stateStore } from "@/store";
import { storeToRefs } from "pinia";

export default {
  components: { BasePage, Notice, ContentView },
  setup() {
    // const storeOfmain = mainStore();
    const storeOfstate = stateStore();
    const contentData = computed(() => {
      return storeToRefs(storeOfstate).activeHtml.value;
    });
    const contentTitle = computed(() => {
      return storeToRefs(storeOfstate).activeTitle.value;
    });
    // const content = computed(() => {
    //   return storeToRefs(store).NoticeOfServiceAndGoods.value[
    //     "correctional_bulletin"
    //   ][0].htmlContent;
    // });
    // console.log("contentcontentcontentcontent", content);
    // const route = useRoute();
    // const contentData = route.params.contentData;
    const data = reactive({
      contentData,
      contentTitle,
    });

    return {
      ...data,
    };
  },
};
</script>

<style scoped>
td {
  border: 1px solid rgb(141, 141, 141) !important;
}
</style>

